import { PARAM_PATH, ROUTER_PATH } from 'ec-constants/routes';
import { postMessageToAndroid, postMessageToIOS } from 'ec-services/nativeMessageSender';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setIsSignedIn } from '~/domains/user';
import { useMobileWebView } from '~/hooks/mobileWebView';
import { deleteAccessToken } from '~/repositories/accessToken';
import { confirmUser, deleteMe, fetchMe, getLikedProducts, patchMe, putPassword } from '~/repositories/user';
import { commonActions } from '~/state/ducks/common';
import { userActions, userSelectors } from '~/state/ducks/user';
import { useToast } from './components/toast';
export function useMe() {
    const dispatch = useDispatch();
    const me = useSelector(userSelectors.me);
    const isInitialized = useSelector(userSelectors.isInitialized);
    const [isLoading, setIsLoading] = useState(false);
    // 関数再生成によるuseEffect内での重複実行を避ける為、useCallbackを使用
    const initialize = useCallback(()=>{
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        fetchMe().then((fetchedMe)=>{
            setIsSignedIn(true);
            dispatch(userActions.setMe(fetchedMe));
            getLikedProducts().then((data)=>{
                dispatch(userActions.setLikeProducts(data));
            });
        }).catch(()=>{
            // 初期化時のプロフィール取得はアクセストークンの有無によって成功も失敗も有り得るのでエラーは握りつぶす
            setIsSignedIn(false);
        }).finally(()=>{
            dispatch(userActions.setIsInitialized(true));
            setIsLoading(false);
        });
    }, [
        dispatch,
        isLoading
    ]);
    return {
        initialize,
        isInitialized,
        me
    };
}
export function useSignOut() {
    const dispatch = useDispatch();
    const { setSuccessMessage } = useToast();
    const { t } = useTranslation('toast');
    //関数再生成によるuseEffect内での重複実行を避ける為、useCallbackを使用
    const signOut = useCallback(async ()=>{
        return deleteAccessToken().then(()=>{
            setIsSignedIn(false);
            dispatch(commonActions.signOut());
            setSuccessMessage(t('signOutDone'));
            window.location.replace(ROUTER_PATH.HOME);
            return true;
        }).catch(()=>{
            return false;
        });
    }, [
        dispatch,
        setSuccessMessage,
        t
    ]);
    return {
        signOut
    };
}
export function useDeleteMe() {
    const { isFromIosWebView, isFromAndroidWebView } = useMobileWebView();
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const leaveMembership = async ()=>{
        setApiError(null);
        setIsLoading(true);
        return await deleteMe().then(()=>{
            setIsLoading(false);
            setIsSignedIn(false);
            if (isFromIosWebView) {
                postMessageToIOS({
                    topic: 'account_deactivated'
                });
            }
            if (isFromAndroidWebView) {
                postMessageToAndroid({
                    topic: 'account_deactivated'
                });
            }
            return true;
        }).catch((e)=>{
            setIsLoading(false);
            setApiError(e);
            return false;
        });
    };
    return {
        leaveMembership,
        isLoading,
        apiError
    };
}
export function useUpdateMe() {
    const { me } = useMe();
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [avatar, setAvatar] = useState({
        file: null,
        imageUrl: me?.avatarImageUrl || null
    });
    const dispatch = useDispatch();
    const updateMe = async (email, name, acceptEmailInfo, avatar, removeAvatar)=>{
        setIsLoading(true);
        return await patchMe(email, name, acceptEmailInfo, removeAvatar, avatar).then((updatedMe)=>{
            setIsLoading(false);
            if (avatar) {
                setAvatar({
                    file: avatar,
                    imageUrl: null
                });
            }
            dispatch(userActions.setMe(updatedMe));
            return true;
        }).catch((e)=>{
            setIsLoading(false);
            setApiError(e);
            return false;
        });
    };
    return {
        updateMe,
        setAvatar,
        avatar,
        isLoading,
        apiError
    };
}
export function useEmailUpdateConfirmation() {
    const { t } = useTranslation('toast');
    const { setSuccessMessage } = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const confirmEmailUpdate = async (token)=>{
        setIsLoading(true);
        return await confirmUser(token).then(async ()=>{
            setIsLoading(false);
            return await fetchMe().then((fetchedMe)=>{
                setSuccessMessage(t('emailUpdateConfirmationDone'));
                navigate(ROUTER_PATH.MY_PAGE);
                dispatch(userActions.setMe(fetchedMe));
                return true;
            }).catch(()=>{
                return false;
            });
        }).catch((e)=>{
            setIsLoading(false);
            if (e.errorCode) {
                navigate(PARAM_PATH.ERROR(e.errorCode), {
                    state: {
                        error: e
                    }
                });
            }
            return false;
        });
    };
    return {
        confirmEmailUpdate,
        isLoading
    };
}
export function useForgotPassword() {
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const forgotPassword = async (token, password)=>{
        setIsLoading(true);
        return await putPassword(token, password).then(()=>{
            return true;
        }).catch((e)=>{
            setApiError(e);
            return false;
        }).finally(()=>{
            setIsLoading(false);
        });
    };
    return {
        forgotPassword,
        isLoading,
        apiError
    };
}
