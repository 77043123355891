const errors = {
    '100': {
        title: 'カード番号が入力されていません',
        description: 'カード番号は必須入力です'
    },
    '101': {
        title: 'カード番号が不正です',
        description: '正しいカード番号を数字で入力してください'
    },
    '102': {
        title: 'カード番号の桁数が不正です',
        description: 'カード番号を確認し、入力し直してください'
    },
    '110': {
        title: '有効期限が入力されていません',
        description: '有効期限は必須入力です'
    },
    '111': {
        title: '有効期限が不正です',
        description: '有効期限が不正な値になっています'
    },
    '112': {
        title: '有効期限の桁数が不正です',
        description: '有効期限を確認し、入力し直してください'
    },
    '113': {
        title: '有効期限の月が不正です',
        description: '有効期限を確認し、入力し直してください'
    },
    '121': {
        title: 'セキュリティコードが不正です',
        description: '正しいセキュリティコードを数字で入力してください'
    },
    '122': {
        title: 'セキュリティコードの桁数が不正です',
        description: 'セキュリティコードを確認し、入力し直してください'
    },
    '131': {
        title: '名義人の入力が不正です',
        description: '名義人は英字入力のみです'
    },
    '132': {
        title: '名義人の入力が不正です',
        description: '名義人の入力は51文字までです。'
    },
    '141': {
        title: '発行数フォーマットエラー',
        description: '発行数が不正です'
    },
    '142': {
        title: '発行数フォーマットエラー',
        description: '発行数が不正です'
    },
    '150': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '160': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '161': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '162': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '170': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '180': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '190': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '191': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '501': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '502': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '511': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '512': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '521': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '522': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '531': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '541': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '551': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '552': {
        title: '内部エラー',
        description: '内部エラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '901': {
        title: '処理を完了できませんでした',
        description: '決済代行会社での処理中にエラーが発生しました。時間を置いてから、再度入力し直してください'
    },
    '902': {
        title: '処理を完了できませんでした',
        description: '決済代行会社での処理が混み合っています。時間を置いてから、再度入力し直してください'
    },
    PME1400: {
        title: 'パラメーターエラー',
        description: ''
    },
    PME1401: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1402: {
        title: '支払い手段が必須です',
        description: '支払い手段が必須です。'
    },
    PME1403: {
        title: '操作権限がありません',
        description: '操作権限がありません。'
    },
    PME1405: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1406: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1407: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1408: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1409: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1410: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1411: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1412: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1413: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1414: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1415: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1416: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1417: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1418: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1421: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1422: {
        title: 'バリデーションエラー',
        description: '',
        transitionDestinationLabel: '戻って入力しなおす'
    },
    PME1423: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1424: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1425: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1426: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1428: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1429: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1431: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1451: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1500: {
        title: 'サーバーエラーが発生しました',
        description: 'サーバーエラーが発生しました。後ほど時間を置いて再度お試しください。'
    },
    PME1501: {
        title: 'サーバーエラーが発生しました',
        description: 'サーバーエラーが発生しました。後ほど時間を置いて再度お試しください。'
    },
    PME1502: {
        title: 'サーバーエラーが発生しました',
        description: 'サーバーエラーが発生しました。後ほど時間を置いて再度お試しください。'
    },
    PME1503: {
        title: 'サービスが一時的に利用できません',
        description: 'サーバーのメンテナンス等でリクエストは完了しませんでした。後ほど時間を置いて再度お試してください。'
    },
    PME1504: {
        title: 'タイムアウトしました',
        description: 'リクエストがタイムアウトしました。後ほど時間を置いて再度お試しください。'
    },
    PME1505: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1506: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1507: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1508: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1510: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME1511: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME2001: {
        title: '決済に失敗しました',
        description: '支払い方法が正しく入力されているか、または有効なクレジットカードが入力されているかご確認お願いします。'
    },
    PME2002: {
        title: 'クレジットカードの上限額に達しています',
        description: '決済金額がご使用のクレジットカードの上限額に達しています。'
    },
    PME2003: {
        title: 'クレジットカードの有効期限切れ',
        description: 'ご使用のクレジットカードの有効期限が切れています。別のクレジットカードを使用してください。',
        transitionDestinationLabel: '戻って入力しなおす'
    },
    PME2004: {
        title: 'クレジットカードを削除できませんでした',
        description: '何らかの理由でクレジットカードを削除できませんでした。詳細をお調べできる場合がありますので、解決しない場合はサポートまでお問い合わせください。',
        transitionDestinationLabel: '戻って入力しなおす'
    },
    PME2005: {
        title: 'クレジットカードのセキュリティコードが不正です',
        description: 'クレジットカードのセキュリティコードが不正です。カード情報をご確認の上入力を修正してください。',
        transitionDestinationLabel: '戻って入力しなおす'
    },
    PME2006: {
        title: 'クレジットカードの確認に失敗しました',
        description: '有効なクレジットカードであることをご確認ください。',
        transitionDestinationLabel: '戻って入力しなおす'
    },
    PME2007: {
        title: 'クレジットカードの確認に失敗しました',
        description: '有効なクレジットカードであることをご確認ください。',
        transitionDestinationLabel: '戻って入力しなおす'
    },
    PME2008: {
        title: '決済エラーが発生しました',
        description: '予期せぬエラーが発生したため、購入を中断しました。時間をおいてもう一度お試しください。'
    },
    PME2009: {
        title: 'ご指定いただいたお支払い方法がご利用いただけません',
        description: '決済会社へお問い合わせいただくか、別のお支払い方法をご利用ください。'
    },
    PME2010: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました'
    },
    PME2012: {
        title: '決済エラーが発生しました',
        description: '予期せぬエラーが発生したため、購入を中断しました。時間をおいてもう一度お試しください。'
    },
    PME2013: {
        title: 'クレジットカードの登録に失敗しました',
        description: 'サポートまでお問い合わせください。',
        transitionDestinationLabel: '戻って入力しなおす'
    },
    PME3001: {
        title: '商品の在庫がなくなりました',
        description: '手続き中に商品の在庫がなくなりました。商品をハートボタンからお気に入り登録すると、在庫が復活（補充）された際にお知らせを受け取ることができます。',
        transitionDestinationLabel: '商品ページへ戻る'
    },
    PME3002: {
        title: 'クーポンがありません',
        description: '入力されたクーポンが存在しません。お手数ですが、再度注文確定をお願いいたします。',
        transitionDestinationLabel: '戻る'
    },
    PME3003: {
        title: 'クーポンが使用できません',
        description: '入力されたクーポンの有効期限が切れたため、使用できません。お手数ですが、再度注文確定をお願いいたします。',
        transitionDestinationLabel: '戻る'
    },
    PME3004: {
        title: 'クーポンが使用できません',
        description: '入力されたクーポンが先着限定数を超えたため、使用できません。お手数ですが、再度注文確定をお願いいたします。',
        transitionDestinationLabel: '戻る'
    },
    PME3005: {
        title: 'クーポンがありません',
        description: '入力されたクーポンの対象ユーザーではないため、使用できません。お手数ですが、再度注文確定をお願いいたします。',
        transitionDestinationLabel: '戻る'
    },
    PME3006: {
        title: 'クーポンがありません',
        description: '支払方法が入力されたクーポンの対象外です。お手数ですが、再度注文確定をお願いいたします。',
        transitionDestinationLabel: '戻る'
    },
    PME3007: {
        title: 'クーポンが使用できません',
        description: '入力されたクーポンは手続き中に利用上限に達したため、使用できません。お手数ですが、再度注文確定をお願いいたします。',
        transitionDestinationLabel: '戻る'
    },
    PME3008: {
        title: '在庫の確保に失敗',
        description: '申し訳ありません。在庫が確保できませんでした。お気に入り登録をすると、在庫が復活（補充）された時にお知らせします。'
    },
    PME3009: {
        title: '無効な配送先住所です',
        description: '配送先の住所はこの商品の配送範囲外です。'
    },
    PME3010: {
        title: '無効な配送先住所です',
        description: '配送先住所が無効です。恐れ入りますが住所をご確認ください。'
    },
    PME3011: {
        title: '無効な配送先住所です',
        description: '選択された配送先住所は既に削除されています。新しい届け先住所を入力してください。'
    },
    PME3012: {
        title: '無効な住所です',
        description: '選択された購入者の配送先宛名は既に削除されています。新しい配送先宛名を入力してください。'
    },
    PME3013: {
        title: '決済に失敗しました',
        description: '支払い方法が正しく入力されているか、または有効なクレジットカードが入力されているかご確認お願いします。'
    },
    PME3014: {
        title: '指定したカード情報は削除されています',
        description: '指定したカード情報は削除されています。'
    },
    PME3015: {
        title: 'キャンセル処理エラー',
        description: 'キャンセル処理が可能な期間を超えたため、処理が完了できませんでした。'
    },
    PME3016: {
        title: '支払い方法が正しくありません',
        description: '支払い方法が正しくありません。有効な支払い方法を選択して再度お試しください。'
    },
    PME3017: {
        title: 'その商品タイプは購入できません',
        description: 'その商品タイプは購入できません。別の商品をお試しください。'
    },
    PME3018: {
        title: 'その商品分量は購入できません',
        description: 'その商品分量は購入できません。別の分量をお試しください。'
    },
    PME3019: {
        title: 'ご回答済みです',
        description: 'すでにご注文への評価にご回答いただいています。',
        transitionDestinationLabel: '注文履歴に戻る'
    },
    PME3020: {
        title: 'お届け日を選択しなおしてください',
        description: '注文手続き中に指定可能なお届け日が更新されました。お手数ですが、注文手続きページよりお届け日を選択しなおしてください。',
        transitionDestinationLabel: '注文手続きページに戻る'
    },
    PME3021: {
        title: 'この商品はふるさと納税から削除されました',
        description: '現在この商品はふるさと納税で注文できません。'
    },
    PME3025: {
        title: '商品情報が変更されました',
        description: '注文手続き中に商品の「お届け日」または「発送予定日」の範囲や設定が変更されました。お手数ですが「お届け日」または「発送予定日」を改めてご確認の上、ご注文ください。',
        transitionDestinationLabel: '注文手続きページに戻る'
    },
    PME3026: {
        title: '商品情報が変更されました',
        description: '注文手続き中に商品の「お届け日」または「発送予定日」の範囲や設定が変更されました。お手数ですが「お届け日」または「発送予定日」を改めてご確認の上、ご注文ください。',
        transitionDestinationLabel: '注文手続きページに戻る'
    },
    PME3028: {
        title: '商品情報が変更されました',
        description: '注文手続き中に商品の「商品価格」が変更されました。お手数ですが「商品価格」または「納税額」を改めてご確認の上、ご注文ください。',
        transitionDestinationLabel: '注文手続きページに戻る'
    },
    PME3029: {
        title: '商品情報が変更されました',
        description: '注文手続き中に商品の「クール区分」が変更されました。お手数ですが「クール代」または「納税額」を改めてご確認の上、ご注文ください。',
        transitionDestinationLabel: '注文手続きページに戻る'
    },
    PME3030: {
        title: '注文エラーが発生しました',
        description: 'お手数ですが、注文手続き画面から再度やり直してください。',
        transitionDestinationLabel: '注文手続きページに戻る'
    },
    PME1404: {
        title: 'お探しのページが見つかりません',
        description: ' URLが間違っているか、ページが削除されています。\nURLをご確認の上で再度アクセスいただくか、\n検索ボックスなどからお目当てのページをお探しください。'
    },
    PME4001: {
        title: '既にメールアドレスは確認済みです',
        description: '既にメールアドレスは確認済みです。'
    },
    PME4002: {
        title: '会員登録確認のURLが不正です',
        description: '会員登録確認のURLが不正です。メールに添付されているURLを再度ご確認ください。'
    },
    PME4003: {
        title: 'リンクの有効期限が切れました',
        description: 'リンクの有効期限が切れました。お手数ですが再度会員登録を行ってください。'
    },
    PME4004: {
        title: '現在のパスワードが正しくありません',
        description: '現在のパスワードが正しくありません。'
    },
    PME4005: {
        title: '名前に不正な文字列が含まれています',
        description: '名前に不正な文字列が含まれています。'
    },
    PME4006: {
        title: 'ニックネームは30文字以内で入力してください',
        description: 'ニックネームは30文字以内で入力してください。'
    },
    PME4007: {
        title: '退会処理が完了できませんでした',
        description: '退会処理が完了できませんでした。くわしくはサポートまでお問い合わせください。'
    },
    PME4008: {
        title: '外部サービス認証に失敗',
        description: '外部サービス認証に失敗しました。時間を置いて後ほどお試しください。'
    },
    PME4009: {
        title: 'ユーザー登録に失敗',
        description: 'ユーザー情報を登録することができませんでした。'
    },
    PME4010: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME4011: {
        title: '不明なエラーが発生しました',
        description: '不明なエラーが発生しました。'
    },
    PME4012: {
        title: '外部サービスからemailアドレスを取得できませんでした',
        description: 'emailの提供を許可していただき、再度お試しください。'
    },
    PME5001: {
        title: '住所を削除できませんでした。',
        description: '定期商品の配送先で削除する住所を使用している場合は、先に配送先住所の変更を行った後に、再度削除してください。解決しない場合はサポートまでお問い合わせください。'
    },
    PME5002: {
        title: '画像のアップロードに失敗しました。',
        description: '画像のアップロードに失敗しました。'
    },
    PME5003: {
        title: 'リセットパスワードトークンが見つかりません',
        description: 'リセットパスワードトークンが見つかりません。'
    },
    PME5004: {
        title: 'リセットパスワードトークンの有効期限切れ',
        description: 'リセットパスワードトークンの有効期限が切れています。お手数ですが再度パスワードの再発行を行ってください。'
    },
    PME5005: {
        title: 'すでに生産者申し込みは申請済みです',
        description: 'すでに生産者申し込みは申請済みです。'
    },
    PME5006: {
        title: '支払い情報を削除できませんでした。',
        description: '定期商品の支払い方法で削除する支払い情報を使用している場合は、先に支払い情報の変更を行った後に、再度削除してください。解決しない場合はサポートまでお問い合わせください。'
    },
    PME6000: {
        title: '外部サービスエラー',
        description: 'サービスの一部で問題が発生しています。しばらくたってから再度お試しください。。'
    },
    PME6100: {
        title: '画像の処理に失敗しました。',
        description: '画像の処理に失敗しました。しばらく経ってから再度お試しください。'
    },
    PME6200: {
        title: '決済エラーが発生しました',
        description: 'ポイントサーバでエラーが発生しました。'
    },
    PME7001: {
        title: 'すでにフォローしているため、フォローできませんでした',
        description: 'すでにフォローしているため、フォローできませんでした。'
    },
    PME7002: {
        title: 'すでにいいねしているため、いいねできませんでした',
        description: 'すでにいいねしているため、いいねできませんでした。'
    },
    PME7003: {
        title: 'いいねしていないため、いいねを取り消せませんでした',
        description: 'いいねしていないため、いいねを取り消せませんでした。'
    },
    PME7004: {
        title: 'フォローしていないため、フォローを取り消せませんでした',
        description: 'フォローしていないため、フォローを取り消せませんでした。'
    },
    PME8001: {
        title: '同じ生産者同士でのメッセージのやりとりはできません',
        description: '同じ生産者同士でのメッセージのやりとりはできません。'
    },
    PME8002: {
        title: '可能な新規お問い合わせの上限数を超えました',
        description: '可能な新規お問い合わせの上限数を超えました。時間をあけて再度お試しください。'
    },
    PME8003: {
        title: '投稿ができません',
        description: '投稿ができません。時間をあけて再度お試しください。'
    },
    // js内部エラー
    PMEInternal: {
        title: 'エラーが発生しました',
        description: 'エラーが発生しました。後ほど時間を置いて再度お試しください。'
    },
    /**
   * atone決済情報取得失敗時エラー
   * @deprecated
   */ PMEI_AtoneInitRequest: {
        title: '不正なエラーが発生しました',
        description: 'エラーが発生しました。後ほど時間を置いて再度お試しください。',
        transitionDestinationLabel: '戻って入力しなおす'
    },
    /**
   * nppg決済情報取得失敗時エラー
   */ PMEI_NppgInitRequest: {
        title: '不正なエラーが発生しました',
        description: 'エラーが発生しました。後ほど時間を置いて再度お試しください。',
        transitionDestinationLabel: '戻って入力しなおす'
    }
};
export default errors;
