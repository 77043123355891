const category = {
    list: {
        title: 'カテゴリからさがす'
    },
    findCategoriesTitle: '{{categoryName}}からさがす',
    detail: {
        title: '{{categoryName}}の産直通販・お取り寄せ商品',
        recommendTitle: '{{categoryName}}のおすすめカテゴリ',
        varietyTitle: '{{categoryName}}の品種で選ぶ',
        keywordTitle: '{{categoryName}}のおすすめキーワード',
        filterCategoriesTitle: '{{categoryName}}を絞り込む',
        findRootCategoriesTitle: '他のカテゴリからさがす',
        findCategoriesTitle: '$t(findCategoriesTitle)',
        totalProductsCount: '{{totalCount}}件の商品',
        productsNotFound: '条件に一致する商品は見つかりませんでした。\n他の条件で検索してみてください。'
    }
};
export default category;
