export function useMobileWebView() {
    const fromIosWebView = ()=>{
        if (window.navigator.userAgent.match(/com.pokem.pocketmarche/)) {
            return true;
        }
        return false;
    };
    const fromAndroidWebView = ()=>{
        if (window.navigator.userAgent.match(/PocketMarcheAndroidWebView/)) {
            return true;
        }
        return false;
    };
    const isFromIosWebView = fromIosWebView();
    const isFromAndroidWebView = fromAndroidWebView();
    return {
        isFromIosWebView,
        isFromAndroidWebView
    };
}
